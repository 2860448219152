import { Link } from 'gatsby';
import React, { ReactNode } from 'react';
import BaseComponent from '../../../base/base-component';
import { IReadTheReportTableOfContentItemProperties } from './table-of-content-item-interface';
import './table-of-content-item.scss';

export default class ReadTheReportTableOfContentItem extends BaseComponent<IReadTheReportTableOfContentItemProperties> {
  public render(): ReactNode {
    const { title, url, slug } = this.props;

    let isActive = false;

    const isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      isActive =
        `${window.location.pathname}${window.location.hash}` === url ||
        `${window.location.pathname}${window.location.hash}/  ` === url;
    }
    const classNames = [
      'c-table-content__item',
      isActive ? 'is-active' : '',
    ].join(' ');

    return (
      <Link to={url} className={classNames}>
        {title}
      </Link>
    );
  }
}
